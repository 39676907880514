<template>
    <v-container fluid>
        <v-card flat class="px-3 mt-3">
            <v-row no-gutters v-if="allRegisteredKiosks.length !== 0">
                <v-col :cols="kioskListDimensions.cols" v-for="(kiosk, i) in allRegisteredKiosks" :key="i">
                    <v-card flat class="pa-2 ma-1 my-3 cardBorderOffline"
                        :class="getCardClass(kiosk.data().onlineStatus)">
                        <v-row no-gutters>
                            <v-col cols="8">
                                <v-row class="fill-height" no-gutters>
                                    <v-col cols="12">
                                        <span class="font-weight-bold">{{ kiosk.data().name }}</span>
                                        <br>
                                        <span class="text-caption">{{ kiosk.data().location }}</span>
                                    </v-col>
                                    <v-col cols="12" align-self="end" v-if="kiosk.data().avatarInfo !== undefined">
                                        <v-btn outlined color="primary" @click="viewAnalytics(kiosk)" small>
                                            <v-icon left color="primary">
                                                mdi-google-analytics
                                            </v-icon>
                                            Analytics</v-btn>
                                    </v-col>
                                    <v-col v-else>
                                        <span>Kiosk not configured</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="4" v-if="kiosk.data().avatarList !== undefined">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-img :src=getImage(kiosk.data()) contain height="100"></v-img>
                                    </v-col>
                                    <v-col cols="12" align="center" class="text-caption font-weight-bold">
                                        <span>{{ kiosk.data().currentAvatar.avatarName }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-else no-gutters align="center">
                <v-card width="90%" flat>
                    <v-card-text class="font-weight-bold">
                        No Registered kiosks
                    </v-card-text>
                </v-card>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "kioskList",
    computed: {
        ...mapGetters(['allRegisteredKiosks', 'avatarList', 'kioskListDimensions'])
    },
    methods: {
        getColor(status) {
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
        getCardClass(status) {
            if (status === "Unavailable") return "cardBorderOffline";
            if (status === "Busy") return "cardBorderOnline";
            if (status === "Available") return "cardBorderBusy";
        },
        getImage(avatar) {
            let temp = this.avatarList.filter(ava => ava.avatarId === avatar.currentAvatar.avatarId)
            return 'https://strapi.arsconnect.com' + temp[0].avatarImage.url
        },
        viewAnalytics(kiosk) {
            this.$store.commit("setSelectKioskAnalytics", kiosk.id)
            this.$router.push('/kioskAnalytics')
            this.$store.dispatch('getKioskAnalytics', {
                type: 'Daily',
                start: null,
                end: null
            })
        }
    }
}
</script>
<style>
.cardBorderOffline {
    border-left: 6px solid #ff5252 !important;
    border-top: 0.5px solid #bdbdbd !important;
    border-bottom: 0.5px solid #bdbdbd !important;
    border-right: 0.5px solid #bdbdbd !important;
}

.cardBorderOnline {
    border-left: 6px solid #4caf50 !important;
    border-top: 0.5px solid #bdbdbd !important;
    border-bottom: 0.5px solid #bdbdbd !important;
    border-right: 0.5px solid #bdbdbd !important;
}

.cardBorderBusy {
    border-left: 6px solid #4caf50 !important;
    border-top: 0.5px solid #bdbdbd !important;
    border-bottom: 0.5px solid #bdbdbd !important;
    border-right: 0.5px solid #bdbdbd !important;
}
</style>