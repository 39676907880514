import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"px-3 mt-3",attrs:{"flat":""}},[(_vm.allRegisteredKiosks.length !== 0)?_c(VRow,{attrs:{"no-gutters":""}},_vm._l((_vm.allRegisteredKiosks),function(kiosk,i){return _c(VCol,{key:i,attrs:{"cols":_vm.kioskListDimensions.cols}},[_c(VCard,{staticClass:"pa-2 ma-1 my-3 cardBorderOffline",class:_vm.getCardClass(kiosk.data().onlineStatus),attrs:{"flat":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"8"}},[_c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(kiosk.data().name))]),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(kiosk.data().location))])]),(kiosk.data().avatarInfo !== undefined)?_c(VCol,{attrs:{"cols":"12","align-self":"end"}},[_c(VBtn,{attrs:{"outlined":"","color":"primary","small":""},on:{"click":function($event){return _vm.viewAnalytics(kiosk)}}},[_c(VIcon,{attrs:{"left":"","color":"primary"}},[_vm._v(" mdi-google-analytics ")]),_vm._v(" Analytics")],1)],1):_c(VCol,[_c('span',[_vm._v("Kiosk not configured")])])],1)],1),(kiosk.data().avatarList !== undefined)?_c(VCol,{attrs:{"cols":"4"}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VImg,{attrs:{"src":_vm.getImage(kiosk.data()),"contain":"","height":"100"}})],1),_c(VCol,{staticClass:"text-caption font-weight-bold",attrs:{"cols":"12","align":"center"}},[_c('span',[_vm._v(_vm._s(kiosk.data().currentAvatar.avatarName))])])],1)],1):_vm._e()],1)],1)],1)}),1):_c(VRow,{attrs:{"no-gutters":"","align":"center"}},[_c(VCard,{attrs:{"width":"90%","flat":""}},[_c(VCardText,{staticClass:"font-weight-bold"},[_vm._v(" No Registered kiosks ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }